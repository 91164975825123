import {
  CardContent,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Grid,
  Divider,
  Tooltip,
  IconButton,
  TextField,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import React from "react";
import {
  CardContentComponentProps,
  Field,
} from "../../models/CardContentComponentProps";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import EuroIcon from "@mui/icons-material/Euro";
import { useTranslation } from "react-i18next";

const AllCards: React.FC<CardContentComponentProps> = ({
  fields,
  title,
  onInputChange,
  inputValues,
  section,
}) => {
  const { t } = useTranslation();

  const getDisplayValue = (fieldName: any, inputValues: any) => {
    if (fieldName === "WettelijkeVakantieuren") {
      inputValues["WettelijkeVakantieuren"] = inputValues["UrenBijFullTime"] * 4;
    }
    if (fieldName === "FeestdagenInUren") {
      inputValues["FeestdagenInUren"] = Math.round(6 * 8 * (inputValues["UrenBijFullTime"] / 40));
    }
    return inputValues[fieldName] || "";
  };

  return (

    <CardContent sx={{ px: 0 }}>
      <Accordion>
        <AccordionSummary
          sx={{ backgroundColor: "#f2f2f2" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="h5" component="div" sx={{ mb: 2 }}>
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ marginTop: "16px" }}>
          <Grid container>
            {fields.map((field: Field, index) => (
              <Grid item xs={12} key={t(field.name)}>
                {index !== 0 && <Divider sx={{ my: 2 }} />}
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{ display: "flex", alignItems: "center", mb: 1 }}
                  >
                    <Typography sx={{ textAlign: { xs: "left" } }}>
                      {field.label}
                    </Typography>

                    <Tooltip title={field.description}>
                      <IconButton aria-label="info">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} sm={4} sx={{ mb: 1 }}>
                    <TextField
                      id={`outlined-basic-${section}-${t(field.name)}`}
                      variant="outlined"
                      type={field.type}
                      fullWidth
                      disabled={field.disabled}
                      value={getDisplayValue(field.name, inputValues)}
                      onChange={(e) =>
                        onInputChange(section, field.name, e.target.value)
                      }
                      InputProps={
                        t(field.name) === "BrutoMaandsalaris"
                          ? {
                            startAdornment: (
                              <InputAdornment position="start">
                                <EuroIcon />
                              </InputAdornment>
                            ),
                          }
                          : undefined
                      }
                      select={field.type === "select"}
                    >
                      {field.type === "select" &&
                        field.options?.map((option, optionIndex) => (
                          <MenuItem
                            key={optionIndex}
                            value={option.value.toString()}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </CardContent>

  );
};

export default AllCards;
