export interface InfoInput {
    BrutoMaandsalaris: number;
    Vakantiegeld: string;
    Werknemersverzekering: string;
    Inkomensafhankelijke: string;
    Pensioenopbouw: string;
    Werkplek: string;
    OverigePersoneleKosten: string;
    WettelijkeVakantieuren: number;
    FeestdagenInUren: number;
    Verzuimuren: number;
    UrenBijFullTime: number;
    BovenwettelijkeVakantieuren: number;
    Scholingsuren: number;
    Opleidingskosten: number;
    Maand13: string;
    BonusRegeling: string;
    WoonWerk: string;
    AutoVanDeZaak: string;
    WerkplekFlexMedewerkers: string;
    LeegloopRisicoFactor: number;
    ContractduurVast:number;
    ContractduurUitzendkracht:number;
    ContractduurZZP:number;
    ContractduurDetacheren:number;
    SectorZZPCorrectiefactor: string;
    ArbeidsmarktKrapteVast: string;
    ArbeidsmarktKrapteFlex: string;
}

export enum TalentCategoryName{
    Vast = "vast",
    zzpdirect = "zzpdirect",
    zzpindirect = "zzpindirect",
    uitzenden = "uitzenden",
    detacheren = "detacheren"
}