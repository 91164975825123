import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, Divider, Grid, Typography } from "@mui/material";
import { InfoInput } from "../models/InfoInput";
import { TalentCost } from "../methodes/TalentCost";
import { useTranslation } from "react-i18next";
import React from "react";
import { ReferenceDataService } from "../services/ReferencesService";
import { TalentFactory } from "../methodes/TalentFactory";
import { LaborMarketDataModel } from "../models/LaborMarketDataModel";
import { SectorCorrectiefactor } from "../models/SectorCorrectiefactor";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface CardsProps {
    infoInput: InfoInput;
}

const Result: React.FC<CardsProps> = ({ infoInput }) => {

    const { t } = useTranslation();
    const [laborMarketData, setLaborMarketData] = React.useState<LaborMarketDataModel[]>([]);
    const [sectorCorrectiefactor, setSectorCorrectiefactor] = React.useState<SectorCorrectiefactor[]>([]);

    React.useEffect(() => {
        ReferenceDataService.SectorCorrectiefactor().then((data) => {
            setLaborMarketData(data.laborMarketData ?? []);
            setSectorCorrectiefactor(data.sectors ?? []);
        });
    });
    const talentCostVast = TalentFactory.getVastTalent();
    talentCostVast.infoInput = infoInput;
    talentCostVast.laborMarketData = laborMarketData;
    talentCostVast.sectorCorrectiefactor = sectorCorrectiefactor;


    const talentCostUitzenden = TalentFactory.getUitzendenTalent();
    talentCostUitzenden.infoInput = infoInput;
    talentCostUitzenden.laborMarketData = laborMarketData;
    talentCostUitzenden.sectorCorrectiefactor = sectorCorrectiefactor;

    const talentCostZZPDirect = TalentFactory.getZzpdirectTalent();
    talentCostZZPDirect.infoInput = infoInput;
    talentCostZZPDirect.laborMarketData = laborMarketData;
    talentCostZZPDirect.sectorCorrectiefactor = sectorCorrectiefactor;

    const talentCostZZPIndirect = TalentFactory.getZzpInDirectTalent();
    talentCostZZPIndirect.infoInput = infoInput;
    talentCostZZPIndirect.laborMarketData = laborMarketData;
    talentCostZZPIndirect.sectorCorrectiefactor = sectorCorrectiefactor;

    const talentCostDetacheren = TalentFactory.getDetacherenTalent();
    talentCostDetacheren.infoInput = infoInput;
    talentCostDetacheren.laborMarketData = laborMarketData;
    talentCostDetacheren.sectorCorrectiefactor = sectorCorrectiefactor;

    const calculations = [
        { label: "Jaarlijks bruto basissalaris", valueVast: TalentCost.formatNumber(talentCostVast.calculateJaarlijksSalaris()), valueUitzenden: TalentCost.formatNumber(talentCostUitzenden.calculateJaarlijksSalaris()), valueZZPDirect: TalentCost.formatNumber(talentCostZZPDirect.calculateJaarlijksSalaris()), valueZZPIndirect: TalentCost.formatNumber(talentCostZZPIndirect.calculateJaarlijksSalaris()), valueDetacheren: TalentCost.formatNumber(talentCostDetacheren.calculateJaarlijksSalaris()) },
        { label: "Totale compensatie kosten excl. werkplek", valueVast: TalentCost.formatNumber(talentCostVast.calculateTotaleCompensatieKosten()), valueUitzenden: TalentCost.formatNumber(talentCostUitzenden.calculateTotaleCompensatieKosten()), valueZZPDirect: TalentCost.formatNumber(talentCostZZPDirect.calculateTotaleCompensatieKosten()), valueZZPIndirect: TalentCost.formatNumber(talentCostZZPIndirect.calculateTotaleCompensatieKosten()), valueDetacheren: TalentCost.formatNumber(talentCostDetacheren.calculateTotaleCompensatieKosten()) },
        { label: "Arbeidsmarkt correctiefactoren", valueVast: TalentCost.formatNumber(talentCostVast.calculateArbeidsmarktCorrectieFactor()), valueUitzenden: TalentCost.formatNumber(talentCostUitzenden.calculateArbeidsmarktCorrectieFactor()), valueZZPDirect: TalentCost.formatNumber(talentCostZZPDirect.calculateArbeidsmarktCorrectieFactor()), valueZZPIndirect: TalentCost.formatNumber(talentCostZZPIndirect.calculateArbeidsmarktCorrectieFactor()), valueDetacheren: TalentCost.formatNumber(talentCostDetacheren.calculateArbeidsmarktCorrectieFactor()) },
        { label: "Werkplek kosten", valueVast: TalentCost.formatNumber(talentCostVast.calculateWerkplekKosten()), valueUitzenden: TalentCost.formatNumber(talentCostUitzenden.calculateWerkplekKosten()), valueZZPDirect: TalentCost.formatNumber(talentCostZZPDirect.calculateWerkplekKosten()), valueZZPIndirect: TalentCost.formatNumber(talentCostZZPIndirect.calculateWerkplekKosten()), valueDetacheren: TalentCost.formatNumber(talentCostDetacheren.calculateWerkplekKosten()) },
        { label: "Kosten per jaar voor werk/opdrachtgever", valueVast: TalentCost.formatNumber(talentCostVast.calculateJaarlijkseKosten()), valueUitzenden: TalentCost.formatNumber(talentCostUitzenden.calculateJaarlijkseKosten()), valueZZPDirect: TalentCost.formatNumber(talentCostZZPDirect.calculateJaarlijkseKosten()), valueZZPIndirect: TalentCost.formatNumber(talentCostZZPIndirect.calculateJaarlijkseKosten()), valueDetacheren: TalentCost.formatNumber(talentCostDetacheren.calculateJaarlijkseKosten()) },
        { label: "Tarief per uur voor declarabele uren", valueVast: TalentCost.formatNumber(talentCostVast.calculateProductiefUurtarief()), valueUitzenden: TalentCost.formatNumber(talentCostUitzenden.calculateProductiefUurtarief()), valueZZPDirect: TalentCost.formatNumber(talentCostZZPDirect.calculateProductiefUurtarief()), valueZZPIndirect: TalentCost.formatNumber(talentCostZZPIndirect.calculateProductiefUurtarief()), valueDetacheren: TalentCost.formatNumber(talentCostDetacheren.calculateProductiefUurtarief()) },
        { label: "Totale investering verwachte contractduur", valueVast: TalentCost.formatNumber(talentCostVast.calculateTotaleInvestering()), valueUitzenden: TalentCost.formatNumber(talentCostUitzenden.calculateTotaleInvestering()), valueZZPDirect: TalentCost.formatNumber(talentCostZZPDirect.calculateTotaleInvestering()), valueZZPIndirect: TalentCost.formatNumber(talentCostZZPIndirect.calculateTotaleInvestering()), valueDetacheren: TalentCost.formatNumber(talentCostDetacheren.calculateTotaleInvestering()) },
        { label: "Vakantiegeld", valueVast: TalentCost.formatNumber(talentCostVast.calculateVakantiegeld()), valueUitzenden: TalentCost.formatNumber(talentCostUitzenden.calculateVakantiegeld()), valueZZPDirect: TalentCost.formatNumber(talentCostZZPDirect.calculateVakantiegeld()), valueZZPIndirect: TalentCost.formatNumber(talentCostZZPIndirect.calculateVakantiegeld()), valueDetacheren: TalentCost.formatNumber(talentCostDetacheren.calculateVakantiegeld()) },
        { label: "Werknemersverzekering", valueVast: TalentCost.formatNumber(talentCostVast.calculateWerknemersverzekering()), valueUitzenden: TalentCost.formatNumber(talentCostUitzenden.calculateWerknemersverzekering()), valueZZPDirect: TalentCost.formatNumber(talentCostZZPDirect.calculateWerknemersverzekering()), valueZZPIndirect: TalentCost.formatNumber(talentCostZZPIndirect.calculateWerknemersverzekering()), valueDetacheren: TalentCost.formatNumber(talentCostDetacheren.calculateWerknemersverzekering()) },
        { label: "Inkomensafhankelijke bijdrage Zvw", valueVast: TalentCost.formatNumber(talentCostVast.calculateInkomensafhankelijke()), valueUitzenden: TalentCost.formatNumber(talentCostUitzenden.calculateInkomensafhankelijke()), valueZZPDirect: TalentCost.formatNumber(talentCostZZPDirect.calculateInkomensafhankelijke()), valueZZPIndirect: TalentCost.formatNumber(talentCostZZPIndirect.calculateInkomensafhankelijke()), valueDetacheren: TalentCost.formatNumber(talentCostDetacheren.calculateInkomensafhankelijke()) },
        { label: "Pensioenopbouw", valueVast: TalentCost.formatNumber(talentCostVast.calculatePensioenopbouw()), valueUitzenden: TalentCost.formatNumber(talentCostUitzenden.calculatePensioenopbouw()), valueZZPDirect: TalentCost.formatNumber(talentCostZZPDirect.calculatePensioenopbouw()), valueZZPIndirect: TalentCost.formatNumber(talentCostZZPIndirect.calculatePensioenopbouw()), valueDetacheren: TalentCost.formatNumber(talentCostDetacheren.calculatePensioenopbouw()) },
        { label: "13de maand", valueVast: TalentCost.formatNumber(talentCostVast.calculateMaand13()), valueUitzenden: TalentCost.formatNumber(talentCostUitzenden.calculateMaand13()), valueZZPDirect: TalentCost.formatNumber(talentCostZZPDirect.calculateMaand13()), valueZZPIndirect: TalentCost.formatNumber(talentCostZZPIndirect.calculateMaand13()), valueDetacheren: TalentCost.formatNumber(talentCostDetacheren.calculateMaand13()) },
        { label: "Bonusregeling", valueVast: TalentCost.formatNumber(talentCostVast.calculateBonusRegeling()), valueUitzenden: TalentCost.formatNumber(talentCostUitzenden.calculateBonusRegeling()), valueZZPDirect: TalentCost.formatNumber(talentCostZZPDirect.calculateBonusRegeling()), valueZZPIndirect: TalentCost.formatNumber(talentCostZZPIndirect.calculateBonusRegeling()), valueDetacheren: TalentCost.formatNumber(talentCostDetacheren.calculateBonusRegeling()) },
        { label: "Overige personele kosten", valueVast: TalentCost.formatNumber(talentCostVast.calculateOverigePersoneleKosten()), valueUitzenden: TalentCost.formatNumber(talentCostUitzenden.calculateOverigePersoneleKosten()), valueZZPDirect: TalentCost.formatNumber(talentCostZZPDirect.calculateOverigePersoneleKosten()), valueZZPIndirect: TalentCost.formatNumber(talentCostZZPIndirect.calculateOverigePersoneleKosten()), valueDetacheren: TalentCost.formatNumber(talentCostDetacheren.calculateOverigePersoneleKosten()) },
        { label: "Woon-werk / thuiswerk vergoeding", valueVast: TalentCost.formatNumber(talentCostVast.calculateWoonWerk()), valueUitzenden: TalentCost.formatNumber(talentCostUitzenden.calculateWoonWerk()), valueZZPDirect: TalentCost.formatNumber(talentCostZZPDirect.calculateWoonWerk()), valueZZPIndirect: TalentCost.formatNumber(talentCostZZPIndirect.calculateWoonWerk()), valueDetacheren: TalentCost.formatNumber(talentCostDetacheren.calculateWoonWerk()) },
        { label: "Auto van de zaak", valueVast: TalentCost.formatNumber(talentCostVast.calculateAutoVanDeZaak()), valueUitzenden: TalentCost.formatNumber(talentCostUitzenden.calculateAutoVanDeZaak()), valueZZPDirect: TalentCost.formatNumber(talentCostZZPDirect.calculateAutoVanDeZaak()), valueZZPIndirect: TalentCost.formatNumber(talentCostZZPIndirect.calculateAutoVanDeZaak()), valueDetacheren: TalentCost.formatNumber(talentCostDetacheren.calculateAutoVanDeZaak()) },
        { label: "Opleidingskosten", valueVast: TalentCost.formatNumber(talentCostVast.calculateOpleidingskosten()), valueUitzenden: TalentCost.formatNumber(talentCostUitzenden.calculateOpleidingskosten()), valueZZPDirect: TalentCost.formatNumber(talentCostZZPDirect.calculateOpleidingskosten()), valueZZPIndirect: TalentCost.formatNumber(talentCostZZPIndirect.calculateOpleidingskosten()), valueDetacheren: TalentCost.formatNumber(talentCostDetacheren.calculateOpleidingskosten()) },
        { label: "Maximum werkuren per jaar", valueVast: talentCostVast.calculateMaxProductieveUren(), valueUitzenden: talentCostUitzenden.calculateMaxProductieveUren(), valueZZPDirect: talentCostZZPDirect.calculateMaxProductieveUren(), valueZZPIndirect: talentCostZZPIndirect.calculateMaxProductieveUren(), valueDetacheren: talentCostDetacheren.calculateMaxProductieveUren() },
        { label: "Basis verlof- en verzuimuren", valueVast: talentCostVast.calculateWettelijke(), valueUitzenden: talentCostUitzenden.calculateWettelijke(), valueZZPDirect: talentCostZZPDirect.calculateWettelijke(), valueZZPIndirect: talentCostZZPIndirect.calculateWettelijke(), valueDetacheren: talentCostDetacheren.calculateWettelijke() },
        { label: "Extra verlof en opleidingsuren", valueVast: talentCostVast.calculateBovenwettelijke(), valueUitzenden: talentCostUitzenden.calculateBovenwettelijke(), valueZZPDirect: talentCostZZPDirect.calculateBovenwettelijke(), valueZZPIndirect: talentCostZZPIndirect.calculateBovenwettelijke(), valueDetacheren: talentCostDetacheren.calculateBovenwettelijke() },
        { label: "Leegloop risicofactor Flex", valueVast: talentCostVast.calculateLeegloopRisico(), valueUitzenden: talentCostUitzenden.calculateLeegloopRisico(), valueZZPDirect: talentCostZZPDirect.calculateLeegloopRisico(), valueZZPIndirect: talentCostZZPIndirect.calculateLeegloopRisico(), valueDetacheren: talentCostDetacheren.calculateLeegloopRisico() },
        { label: "Declarabele uren per jaar", valueVast: talentCostVast.calculateProductieveUren(), valueUitzenden: talentCostUitzenden.calculateProductieveUren(), valueZZPDirect: talentCostZZPDirect.calculateProductieveUren(), valueZZPIndirect: talentCostZZPIndirect.calculateProductieveUren(), valueDetacheren: talentCostDetacheren.calculateProductieveUren() },
    ];


    return (
        <Box sx={{ minWidth: 275, my: 4 }}>
            <CardContent sx={{ px: 0 }}>
                <Accordion>
                    <AccordionSummary
                        sx={{ backgroundColor: "#f2f2f2" }}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                            Detail berekeningen
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ marginTop: "16px" }}>
                        <Card variant="outlined" sx={{ mb: 2, backgroundColor: "#f2f2f2" }}>
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={12} sx={{ display: { xs: "none", sm: "flex" }, mb: 1 }}>
                                        <Grid container>
                                            <Grid item xs={4.5}></Grid>
                                            {["Vast", "Uitzenden", "ZZP Direct", "ZZP Indirect", "Detacheren"].map((header, index) => (
                                                <Grid item xs={1.5} key={index}>
                                                    <Typography sx={{ textAlign: "right", fontWeight: "bold" }}>
                                                        {header}
                                                    </Typography>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                    {calculations.map((calc, i) => (
                                        <Grid item xs={12} key={i}>
                                            <Divider sx={{ my: 2 }} />
                                            <Grid
                                                container
                                            >
                                                <Grid item xs={12} sm={4.5} sx={{ mb: 1 }}>
                                                    <Typography sx={{ textAlign: { xs: "left", sm: "left" } }}>
                                                        {calc.label}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={1.5}>
                                                    <Grid
                                                        container>
                                                        <Grid item xs={6}>
                                                            <Typography sx={{ textAlign: { xs: "left", sm: "right" }, fontWeight: "bold", display: { sm: "none" } }}>
                                                                {t("vast")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6} sm={12}>
                                                            <Typography sx={{ textAlign: { xs: "right", sm: "right" } }}>
                                                                {calc.valueVast}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={1.5}>
                                                    <Grid
                                                        container>
                                                        <Grid item xs={6}>
                                                            <Typography sx={{ textAlign: { xs: "left", sm: "right" }, fontWeight: "bold", display: { sm: "none" } }}>
                                                                {t("uitzenden")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6} sm={12}>
                                                            <Typography sx={{ textAlign: { xs: "right", sm: "right" } }}>
                                                                {calc.valueUitzenden}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={1.5}>
                                                    <Grid
                                                        container>
                                                        <Grid item xs={6}>
                                                            <Typography sx={{ textAlign: { xs: "left", sm: "right" }, fontWeight: "bold", display: { sm: "none" } }}>
                                                                {t("zpp_direct")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6} sm={12}>
                                                            <Typography sx={{ textAlign: { xs: "right", sm: "right" } }}>
                                                                {calc.valueZZPDirect}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={1.5} sx={{ p: 0 }}>
                                                    <Grid
                                                        container>
                                                        <Grid item xs={6}>
                                                            <Typography sx={{ textAlign: { xs: "left", sm: "right" }, fontWeight: "bold", display: { sm: "none" } }}>
                                                                {t("zpp_indirect")}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6} sm={12}>
                                                            <Typography sx={{ textAlign: { xs: "right", sm: "right" } }}>
                                                                {calc.valueZZPIndirect}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={1.5}>
                                                    <Grid
                                                        container>
                                                        <Grid item xs={8}>
                                                            <Typography sx={{ textAlign: { xs: "left", sm: "right" }, fontWeight: "bold", display: { sm: "none" } }}>
                                                                {t("detacheren")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={4} sm={12}>
                                                            <Typography sx={{ textAlign: { xs: "right", sm: "right" } }}>
                                                                {calc.valueDetacheren}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </CardContent>
                        </Card>
                    </AccordionDetails>
                </Accordion>
            </CardContent>

        </Box>

    );
}

export default Result;
