import * as React from "react";
import { Grid, Card, Typography } from "@mui/material";
import { Field } from "../models/CardContentComponentProps";
import { InfoInput } from "../models/InfoInput";
import Result from "./Result";
import { useTranslation } from "react-i18next";
import Charts from "./charts/Charts";
import {
  ReferenceDataService
} from "../services/ReferencesService";
import CardContentComponent from "./cards/PriceCard";
import CardContentComponentWithAccordion from "./cards/AllCards";
import { LabelValueModel } from "../models/DataReferenceModel";

interface CardsProps {
  infoInput: InfoInput;
  onInputChange: (
    section: string,
    fieldName: string | number,
    value: string | number
  ) => void;
}

const Cards: React.FC<CardsProps> = ({ infoInput, onInputChange }) => {
  const { t } = useTranslation();
  const [sectors, setSectors] = React.useState<LabelValueModel[]>([]);
  const [laborMarketData, setLaborMarketData] = React.useState<LabelValueModel[]>([]);

  React.useEffect(() => {
    ReferenceDataService.SectorCorrectiefactor().then((data) => {
      const optionsSectors = data.sectors?.map((sector: any) => ({
        label: sector.name,
        value: sector.name,
      }));
      setSectors(optionsSectors ?? []);

      const option = data.laborMarketData?.map((labo: any) => ({
        label: labo.marketTightness,
        value: labo.marketTightness,
      }));
      setLaborMarketData(option ?? []);
    });
  }, []);

  const BrutoMaandsalarisField: Field[] = [
    {
      name: "BrutoMaandsalaris",
      label: t("gross_monthly_salary.label"),
      type: "number",
      description: t("gross_monthly_salary.description"),
    },
  ];

  const requiredFields: Field[] = [
    {
      name: "Vakantiegeld",
      label: t("vacation_money.label"),
      type: "select",
      description: t("vacation_money.description"),
      options: [
        { label: t("yes.label"), value: "Ja" },
        { label: t("no.label"), value: "nee" },
      ],
      disabled: true,
    },
    {
      name: "Werknemersverzekering",
      label: t("employee_insurance.label"),
      type: "select",
      description: t("employee_insurance.description"),
      options: [
        { label: t("yes.label"), value: "Ja" },
        { label: t("no.label"), value: "nee" },
      ],
      disabled: true,
    },
    {
      name: "Inkomensafhankelijke",
      label: t("income_dependent_contribution.label"),
      type: "select",
      description: t("income_dependent_contribution.description"),
      options: [
        { label: t("yes.label"), value: "Ja" },
        { label: t("no.label"), value: "nee" },
      ],
      disabled: true,
    },
    {
      name: "Pensioenopbouw",
      label: t("pension_build_up.label"),
      type: "select",
      description: t("pension_build_up.description"),
      options: [
        { label: t("yes.label"), value: "Ja" },
        { label: t("no.label"), value: "nee" },
      ],
      disabled: true,
    },
    {
      name: "Werkplek",
      label: t("workplace_permanent_employees.label"),
      type: "select",
      description: t("workplace_permanent_employees.description"),
      options: [
        { label: t("yes.label"), value: "Ja" },
        { label: t("no.label"), value: "nee" },
      ],
      disabled: true,
    },
    {
      name: "OverigePersoneleKosten",
      label: t("other_personnel_costs.label"),
      description: t("other_personnel_costs.description"),
      type: "select",
      options: [
        { label: t("yes.label"), value: "Ja" },
        { label: t("no.label"), value: "nee" },
      ],
      disabled: true,
    },
    {
      name: "WettelijkeVakantieuren",
      label: t("statutory_vacation_hours.label"),
      type: "number",
      description: t("statutory_vacation_hours.description"),
      disabled: true,
    },
    {
      name: "FeestdagenInUren",
      label: t("holidays_in_hours.label"),
      type: "number",
      description: t("holidays_in_hours.description"),
      disabled: true,
    },
    {
      name: "Verzuimuren",
      label: t("absence_hours.label"),
      type: "number",
      description: t("absence_hours.description"),
    },
    {
      name: "UrenBijFullTime",
      label: t("full_time_hours.label"),
      type: "number",
      description: t("full_time_hours.description"),
    },
  ];

  const optionalFields: Field[] = [
    {
      name: "BovenwettelijkeVakantieuren",
      label: t("non_statutory_vacation_hours.label"),
      type: "number",
      description: t("non_statutory_vacation_hours.description"),
    },
    {
      name: "Scholingsuren",
      label: t("training_hours.label"),
      type: "number",
      description: t("training_hours.description"),
    },
    {
      name: "Opleidingskosten",
      label: t("training_costs.label"),
      type: "number",
      description: t("training_costs.description"),
    },
    {
      name: "Maand13",
      label: t("thirteenth_month.label"),
      type: "select",
      description: t("thirteenth_month.description"),
      options: [
        { label: t("yes.label"), value: "Ja" },
        { label: t("no.label"), value: "Nee" },
      ],
    },
    {
      name: "BonusRegeling",
      label: t("bonus_scheme.label"),
      type: "select",
      description: t("bonus_scheme.description"),
      options: [
        { label: t("yes.label"), value: "Ja" },
        { label: t("no.label"), value: "Nee" },
      ],
    },
    {
      name: "WoonWerk",
      label: t("commute_work_reimbursement.label"),
      type: "select",
      description: t("commute_work_reimbursement.description"),
      options: [
        { label: t("yes.label"), value: "Ja" },
        { label: t("no.label"), value: "Nee" },
      ],
    },
    {
      name: "AutoVanDeZaak",
      label: t("company_car.label"),
      type: "select",
      description: t("company_car.description"),
      options: [
        { label: t("yes.label"), value: "Ja" },
        { label: t("no.label"), value: "Nee" },
      ],
    },
    {
      name: "WerkplekFlexMedewerkers",
      label: t("workplace_flex_employees.label"),
      type: "select",
      description: t("workplace_flex_employees.description"),
      options: [
        { label: t("yes.label"), value: "Ja" },
        { label: t("no.label"), value: "Nee" },
      ],
    },
    {
      name: "LeegloopRisicoFactor",
      label: t("idle_risk_factor.label"),
      type: "number",
      description: t("idle_risk_factor.description"),
    }
  ];

  const correctionFields: Field[] = [
    {
      name: "ContractduurVast",
      label: t("average_contract_duration_permanent.label"),
      type: "number",
      description: t("average_contract_duration_permanent.description"),
    },
    {
      name: "ContractduurUitzendkracht",
      label: t("average_contract_duration_temp.label"),
      type: "number",
      description: t("average_contract_duration_temp.description"),
    },
    {
      name: "ContractduurZZP",
      label: t("average_contract_duration_freelance.label"),
      type: "number",
      description: t("average_contract_duration_freelance.description"),
    },
    {
      name: "ContractduurDetacheren",
      label: t("average_contract_duration_secondment.label"),
      type: "number",
      description: t("average_contract_duration_secondment.description"),
    },
  ];

  const costFactorsFields: Field[] = [
    {
      name: "SectorZZPCorrectiefactor",
      label: t("sector_freelance_correction_factor.label"),
      type: "select",
      description: t("sector_freelance_correction_factor.description"),
      options: sectors,
    },
    {
      name: "ArbeidsmarktKrapteVast",
      label: t("labor_market_tightness_permanent.label"),
      type: "select",
      description: t("labor_market_tightness_permanent.description"),
      options: laborMarketData,
    },
    {
      name: "ArbeidsmarktKrapteFlex",
      label: t("labor_market_tightness_flex.label"),
      type: "select",
      description: t("labor_market_tightness_flex.description"),
      options: laborMarketData,
    },
  ];

  return (
    <div>
      <Grid container spacing={2}>
        <Card variant="outlined" sx={{ p: 4, mt: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Card sx={{ my: 2 }}>
                <CardContentComponent
                  fields={BrutoMaandsalarisField}
                  title="Bruto maandsalaris bij vast contract"
                  onInputChange={onInputChange}
                  inputValues={infoInput}
                  section="salair"
                />
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <CardContentComponentWithAccordion
                fields={requiredFields}
                title={t("titles.required_compensation_components")}
                onInputChange={onInputChange}
                inputValues={infoInput}
                section="required"
              />
              <CardContentComponentWithAccordion
                fields={costFactorsFields}
                title={t("titles.labor_market_correction_factors")}
                onInputChange={onInputChange}
                inputValues={infoInput}
                section="costFactors"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CardContentComponentWithAccordion
                fields={optionalFields}
                title={t("titles.optional_compensation_components")}
                onInputChange={onInputChange}
                inputValues={infoInput}
                section="optional"
              />
              <CardContentComponentWithAccordion
                fields={correctionFields}
                title={t("titles.duration_of_contracts")}
                onInputChange={onInputChange}
                inputValues={infoInput}
                section="correction"
              />
            </Grid>
          </Grid>
        </Card>
        <Card variant="outlined" sx={{ p: 4, mt: 4 }}>
          <Typography variant="h4" component="h1">
            Resultaten
          </Typography>
          <Grid item xs={12} sm={12}>
            <Charts infoInput={infoInput} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Result infoInput={infoInput} />
          </Grid>
        </Card>
      </Grid>
    </div>
  );
};

export default Cards;
