import * as React from 'react';
import Cards from './components/Page';
import { Container } from '@mui/material';

export default function App() {
  const [infoInput, setInfoInput] = React.useState({
    BrutoMaandsalaris: 1934.40,
    Vakantiegeld: 'Ja',
    Werknemersverzekering: 'Ja',
    Inkomensafhankelijke: 'Ja',
    Pensioenopbouw: 'Ja',
    Werkplek: 'Ja',
    OverigePersoneleKosten: 'Ja',
    WettelijkeVakantieuren: 160,
    FeestdagenInUren: 48,
    Verzuimuren: 104,
    UrenBijFullTime: 40,
    BovenwettelijkeVakantieuren: 40,
    Scholingsuren: 40,
    Opleidingskosten: 2,
    Maand13: 'Ja',
    BonusRegeling: 'Nee',
    WoonWerk: 'Ja',
    AutoVanDeZaak: 'Nee',
    WerkplekFlexMedewerkers: 'Ja',
    LeegloopRisicoFactor: 2,
    ContractduurVast: 9,
    ContractduurUitzendkracht: 2,
    ContractduurZZP: 1,
    ContractduurDetacheren: 1.5,
    SectorZZPCorrectiefactor: 'Standaard',
    ArbeidsmarktKrapteVast: 'Schaars',
    ArbeidsmarktKrapteFlex: 'Schaars',
  });
    
  const handleInputChange = (section: string, fieldName: string | number, value: string | number) => {
    setInfoInput(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  return (
    <Container maxWidth="lg">
      <Cards infoInput={infoInput} onInputChange={handleInputChange}/>
    </Container>
  );
}