import { DataReferenceModel } from "../models/DataReferenceModel";

export class ReferenceDataService {
  private static dataReferenceModel?: DataReferenceModel = undefined;

  static async SectorCorrectiefactor(): Promise<DataReferenceModel> {
    if (!this.dataReferenceModel) {
      let response = await fetch("data.json");
      this.dataReferenceModel = await response.json() as DataReferenceModel;
    }
    return this.dataReferenceModel;
  }
}