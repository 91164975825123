import { TalentCategoryName } from "../models/InfoInput";
import { TalentCost } from "./TalentCost";

export class TalentFactory{

    private static talentCostVast?:TalentCost = undefined;
    private static talentCostUitzenden?:TalentCost = undefined;
    private static talentCostZZPDirect?:TalentCost = undefined;
    private static talentCostZZPIndirect?:TalentCost = undefined;
    private static talentCostDetacheren?:TalentCost = undefined;

    static getVastTalent():TalentCost{
        if(!this.talentCostVast){
            this.talentCostVast = new TalentCost();
            this.talentCostVast.name = TalentCategoryName.Vast;
            this.talentCostVast.OverigePersoneleKosten = 2;
            this.talentCostVast.Maand13 = 8;
            this.talentCostVast.BonusRegeling = 8;
            this.talentCostVast.AutoVanDeZaak = 13;
            this.talentCostVast.WerkplekKosten = 9000;
        }
        return this.talentCostVast;
    }

    static getUitzendenTalent():TalentCost{
        if(!this.talentCostUitzenden){
            this.talentCostUitzenden = new TalentCost();
            this.talentCostUitzenden.name = TalentCategoryName.uitzenden;
            this.talentCostUitzenden.Maand13 = 8;
            this.talentCostUitzenden.BonusRegeling = 8;
            this.talentCostUitzenden.AutoVanDeZaak = 13;
            this.talentCostUitzenden.WerkplekKosten = 9000;
        }
        return this.talentCostUitzenden;
    }

    static getZzpdirectTalent():TalentCost{
        if(!this.talentCostZZPDirect){
            this.talentCostZZPDirect = new TalentCost();
            this.talentCostZZPDirect.name = TalentCategoryName.zzpdirect;
            this.talentCostZZPDirect.WerkplekKosten = 9000;
            this.talentCostZZPDirect.Pensioenopbouw = 15;
        }
        return this.talentCostZZPDirect;
    }

    static getZzpInDirectTalent():TalentCost{
        if(!this.talentCostZZPIndirect){
            this.talentCostZZPIndirect = new TalentCost();
            this.talentCostZZPIndirect.name = TalentCategoryName.zzpindirect;
            this.talentCostZZPIndirect.WerkplekKosten = 9000;
            this.talentCostZZPIndirect.Pensioenopbouw = 15;
        }
        return this.talentCostZZPIndirect;
    }

    static getDetacherenTalent():TalentCost{
        if(!this.talentCostDetacheren){
            this.talentCostDetacheren = new TalentCost();
            this.talentCostDetacheren.name = TalentCategoryName.detacheren;
            this.talentCostDetacheren.Maand13 = 8;
            this.talentCostDetacheren.BonusRegeling = 8;
            this.talentCostDetacheren.AutoVanDeZaak = 13;
            this.talentCostDetacheren.WerkplekKosten = 9000;
        }
        return this.talentCostDetacheren;
    }
}