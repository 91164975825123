import { InfoInput, TalentCategoryName } from "../models/InfoInput";
import { LaborMarketDataModel } from "../models/LaborMarketDataModel";
import { SectorCorrectiefactor } from "../models/SectorCorrectiefactor";

export class TalentCost {
  public name!: string;
  public vakantiegeld: number = 0.96;
  public werknemersverzekering: number = 13;
  public OverigePersoneleKosten: number = 0;
  public Pensioenopbouw: number = 0;
  public Maand13: number = 0;
  public BonusRegeling: number = 0;
  public AutoVanDeZaak: number = 0;
  public WerkplekKosten: number = 0;
  public FullTimeUrenZzp: number = 36;
  public infoInput!: InfoInput;

  public laborMarketData: LaborMarketDataModel[] = [];
  public sectorCorrectiefactor: SectorCorrectiefactor[] = [];


  calculateJaarlijksSalaris(): number {
    return this.infoInput.BrutoMaandsalaris * 12;
  }

  calculateMaxProductieveUren(): number {
    if (this.name === TalentCategoryName.zzpdirect || this.name === TalentCategoryName.zzpindirect || this.name === TalentCategoryName.detacheren) {
      return this.FullTimeUrenZzp * 52;
    }
    return this.infoInput.UrenBijFullTime * 52;
  }

  calculateProductieveUren(): number {
    return this.calculateMaxProductieveUren() + this.calculateWettelijke() + this.calculateBovenwettelijke() + this.calculateLeegloopRisico();
  }

  calculateVakantiegeld(): number {
    return this.infoInput.BrutoMaandsalaris * this.vakantiegeld;
  }

  calculateWerknemersverzekering(): number {
    if (this.name === TalentCategoryName.zzpdirect || this.name === TalentCategoryName.zzpindirect) {
      return (
        (this.calculateJaarlijksSalaris() + this.calculateVakantiegeld()) *
        (this.werknemersverzekering ? this.werknemersverzekering / 100 : 0)
      );
    }
    return (
      (this.calculateJaarlijksSalaris() +
        this.calculateVakantiegeld() +
        this.calculateMaand13() +
        this.calculateBonusRegeling() +
        this.calculateWoonWerk()) *
      (this.werknemersverzekering ? this.werknemersverzekering / 100 : 0)
    );
  }

  calculateInkomensafhankelijke(): number {
    if (this.name === TalentCategoryName.zzpdirect || this.name === TalentCategoryName.zzpindirect

    ) {
      let max = this.calculateJaarlijksSalaris() > 71628 ? 71628 : this.calculateJaarlijksSalaris();
      return max * 0.0532;
    }
    return (
      (this.calculateJaarlijksSalaris() +
        this.calculateVakantiegeld() -
        this.calculateWerknemersverzekering() -
        this.calculatePensioenopbouw()) *
      0.0657
    );
  }

  calculatePensioenopbouw(): number {
    if (this.name === TalentCategoryName.zzpdirect || this.name === TalentCategoryName.zzpindirect) {
      return this.calculateJaarlijksSalaris() * (this.Pensioenopbouw / 100);
    }
    return (
      (this.calculateJaarlijksSalaris() +
        this.calculateVakantiegeld() -
        13500) *
      0.265 *
      0.666
    );
  }

  calculateWerkplekKosten(): number {
    if (this.name === TalentCategoryName.Vast || this.infoInput.WerkplekFlexMedewerkers === "Ja") {
      return this.WerkplekKosten;
    }
    return 0;
  }

  calculateOverigePersoneleKosten(): number {
    if (this.name === TalentCategoryName.Vast) {
      return this.calculateJaarlijksSalaris() * 0.04;
    } else {
      return this.calculateJaarlijksSalaris() * 0.15;
    }
  }

  calculateWettelijke(): number {
    return this.name === TalentCategoryName.zzpdirect || this.name === TalentCategoryName.zzpindirect
      ? -9 * this.FullTimeUrenZzp
      : -(
        Number(this.infoInput.WettelijkeVakantieuren) +
        Number(this.infoInput.FeestdagenInUren) +
        Number(this.infoInput.Verzuimuren)
      );
  }

  calculateBovenwettelijke(): number {
    return this.name === TalentCategoryName.zzpdirect || this.name === TalentCategoryName.zzpindirect
      ? 0
      : -(
        Number(this.infoInput.BovenwettelijkeVakantieuren) +
        Number(this.infoInput.Scholingsuren)
      );
  }

  calculateOpleidingskosten(): number {
    return this.name === TalentCategoryName.zzpdirect || this.name === TalentCategoryName.zzpindirect
      ? 0
      : (this.infoInput.Opleidingskosten
        ? this.infoInput.Opleidingskosten / 100
        : 0) * this.calculateJaarlijksSalaris();
  }

  calculateMaand13(): number {
    return this.infoInput.Maand13 === "Ja"
      ? this.calculateJaarlijksSalaris() *
      (this.Maand13 ? this.Maand13 / 100 : 0)
      : 0;
  }

  calculateBonusRegeling(): number {
    return this.infoInput.BonusRegeling === "Ja"
      ? this.calculateJaarlijksSalaris() *
      (this.BonusRegeling ? this.BonusRegeling / 100 : 0)
      : 0;
  }

  calculateWoonWerk(): number {
    return this.name === TalentCategoryName.zzpdirect || this.name === TalentCategoryName.zzpindirect
      ? 0
      : this.infoInput.WoonWerk === "Ja"
        ? 520
        : 0;
  }

  calculateAutoVanDeZaak(): number {
    if (this.name === TalentCategoryName.zzpdirect || this.name === TalentCategoryName.zzpindirect || this.name === TalentCategoryName.uitzenden) {
      return 0;
    }
    return this.infoInput.AutoVanDeZaak === "Ja"
      ? this.calculateJaarlijksSalaris() *
      (this.AutoVanDeZaak ? this.AutoVanDeZaak / 100 : 0)
      : 0;
  }

  calculateLeegloopRisico(): number {
    if (this.name === TalentCategoryName.zzpdirect || this.name === TalentCategoryName.zzpindirect) {
      return -this.FullTimeUrenZzp * this.infoInput.LeegloopRisicoFactor;
    }
    if (this.name === TalentCategoryName.detacheren) {
      return -this.infoInput.UrenBijFullTime * this.infoInput.LeegloopRisicoFactor;
    }
    return 0;
  }

  calculateArbeidsmarktCorrectieFactor() {
    let correction = 0;
    let model;
    try {
      switch (this.name) {
        case TalentCategoryName.Vast:
          model = this.laborMarketData.find(p => p.marketTightness === this.infoInput.ArbeidsmarktKrapteVast);
          correction = model?.recruitmentKostenVast !== undefined && model?.recruitmentKostenVastPercentage !== undefined ? model.recruitmentKostenVast + this.calculateJaarlijksSalaris() * (model.recruitmentKostenVastPercentage / 100) : 0;
          break;
        case TalentCategoryName.zzpdirect:
          model = this.laborMarketData.find(p => p.marketTightness === this.infoInput.ArbeidsmarktKrapteFlex);
          correction = model?.recruitmentKostenZZPDirect ?? 0;
          break;
        case TalentCategoryName.zzpindirect:
          model = this.laborMarketData.find(p => p.marketTightness === this.infoInput.ArbeidsmarktKrapteFlex);
          correction = model?.zzpIndirectBureaumarge ? this.calculateTotaleCompensatieKosten() * (model.zzpIndirectBureaumarge / 100) : 0;
          break;
        case TalentCategoryName.uitzenden:
          model = this.laborMarketData.find(p => p.marketTightness === this.infoInput.ArbeidsmarktKrapteFlex);
          correction = model?.uitzendenBureauMarge ? this.calculateTotaleCompensatieKosten() * (model.uitzendenBureauMarge / 100) : 0;
          break;
        case TalentCategoryName.detacheren:
          model = this.laborMarketData.find(p => p.marketTightness === this.infoInput.ArbeidsmarktKrapteFlex);
          correction = model?.detacheringBureaumarge ? this.calculateTotaleCompensatieKosten() * (model.detacheringBureaumarge / 100) : 0;
          break;
        default:
          break;
      }
    } catch (error) {
      console.log(error);
    }
    return correction;
  }

  calculateTotaleCompensatieKosten(): number {
    let result = this.calculateJaarlijksSalaris() +
      this.calculateVakantiegeld() +
      this.calculateWerknemersverzekering() +
      this.calculateInkomensafhankelijke() +
      this.calculatePensioenopbouw() +
      this.calculateOverigePersoneleKosten();

    if (this.name === TalentCategoryName.zzpdirect || this.name === TalentCategoryName.zzpindirect) {
      return result;
    } else {
      result = result + this.calculateOpleidingskosten() +
        this.calculateMaand13() +
        this.calculateBonusRegeling() +
        this.calculateWoonWerk() +
        this.calculateAutoVanDeZaak();
      return result;
    }
  }

  calculateJaarlijkseKosten(): number {
    let result = 0;
    switch (this.name) {
      case TalentCategoryName.Vast:
        result = this.calculateTotaleCompensatieKosten() + this.calculateWerkplekKosten() + this.calculateArbeidsmarktCorrectieFactor() / 2;
        break;
      case TalentCategoryName.uitzenden:
        result = (this.calculateProductiefUurtarief() * this.calculateProductieveUren()) + this.calculateWerkplekKosten();
        break;
      case TalentCategoryName.zzpdirect:
        result =
          this.calculateProductiefUurtarief() * this.calculateProductieveUren() + this.calculateWerkplekKosten();
        break;
      case TalentCategoryName.zzpindirect:
        result = this.calculateProductiefUurtarief() * this.calculateProductieveUren() + this.calculateWerkplekKosten();
        break;
      case TalentCategoryName.detacheren:
        result = this.calculateProductiefUurtarief() * this.calculateProductieveUren() + this.calculateWerkplekKosten();
        break;
      default:
        result = 0;
        break;
    }

    return result;
  }

  calculateProductiefUurtarief(): number {
    let result = 0;
    let sectorZzpCorrectieFactor = this.sectorCorrectiefactor.find(p => p.name === this.infoInput.SectorZZPCorrectiefactor)?.factor ?? 1;
    let model = this.laborMarketData.find(p => p.marketTightness === this.infoInput.ArbeidsmarktKrapteFlex);
    let totaalkosten = this.calculateTotaleCompensatieKosten() + this.calculateArbeidsmarktCorrectieFactor();
    switch (this.name) {
      case TalentCategoryName.Vast:
        result = (this.calculateJaarlijkseKosten() - this.WerkplekKosten) / this.calculateProductieveUren();
        break;
      case TalentCategoryName.uitzenden:
        let correctie = model?.uitzendenBureauMarge ? 1 + model?.uitzendenBureauMarge / 100 : 1;
        result = totaalkosten / this.calculateProductieveUren() * correctie;
        break;
      case TalentCategoryName.zzpdirect:
        result = totaalkosten / this.calculateProductieveUren() * sectorZzpCorrectieFactor;
        break;
      case TalentCategoryName.zzpindirect:
        let zzpmarge = model?.zzpIndirectBureaumarge ?? 0;
        result = (totaalkosten / this.calculateProductieveUren()) * sectorZzpCorrectieFactor * (1 + zzpmarge / 100);
        break;
      default:
        let marge = model?.detacheringBureaumarge ?? 0;
        result =
          (totaalkosten / this.calculateProductieveUren()) * sectorZzpCorrectieFactor * (1 + marge / 100);
        break;
    }

    return Number(result.toFixed(2));
  }

  calculateBasisUurtarief(): number {
    let result = 0;
    switch (this.name) {
      case TalentCategoryName.Vast:
        result = this.calculateJaarlijkseKosten() / this.calculateMaxProductieveUren();
        break;
      default:
        result = this.calculateProductiefUurtarief();
        break;
    }
    return Number(result.toFixed(2));
  }

  calculateTotaleInvestering(): number {
    let result = 0;
    switch (this.name) {
      case TalentCategoryName.Vast:
        result =
          this.calculateJaarlijkseKosten() * this.infoInput.ContractduurVast;
        break;
      case TalentCategoryName.uitzenden:
        result =
          (this.calculateJaarlijkseKosten() * this.infoInput.ContractduurUitzendkracht);
        break;
      case TalentCategoryName.detacheren:
        result =
          this.calculateJaarlijkseKosten() *
          this.infoInput.ContractduurDetacheren;
        break;
      default:
        result =
          this.calculateJaarlijkseKosten() * this.infoInput.ContractduurZZP;
        break;
    }
    return Number(result.toFixed(2));
  }

  static getNumber(input: any): number {
    if (isNaN(input)) {
      return 0;
    }
    try {
      return input;
    } catch (error) {
      return 0;
    }
  }

  static formatNumber(input: any): string {
    const formatter = new Intl.NumberFormat('nl-NL', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    if (isNaN(input)) {
      return 'n.v.t';
    }
    try {
      return formatter.format(input);
    } catch (error) {
      return 'n.v.t';
    }
  }
}
