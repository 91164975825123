import { CardContent, Grid, Typography, Tooltip, IconButton, TextField, InputAdornment, MenuItem } from "@mui/material";
import React from "react";
import { CardContentComponentProps, Field } from "../../models/CardContentComponentProps";
import InfoIcon from "@mui/icons-material/Info";
import EuroIcon from "@mui/icons-material/Euro";

const PriceCard: React.FC<CardContentComponentProps> = ({
    fields,
    onInputChange,
    inputValues,
    section,
  }) => (
    <React.Fragment>
      <CardContent>
        <Grid container>
          {fields.map((field: Field) => (
            <Grid item xs={12} key={field.name}>
              <Grid
                container
                direction={{ xs: "column", sm: "row" }}
                alignItems={{ sm: "center" }}
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{ display: "flex", alignItems: "center", mb: 1 }}
                >
                  <Typography sx={{ textAlign: { xs: "left" } }}>
                    {field.label}
                  </Typography>
                  <Tooltip title={field.description}>
                    <IconButton aria-label="info">
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ mb: 1 }}>
                  <TextField
                    id={`outlined-basic-${section}-${field.name}`}
                    variant="outlined"
                    type={field.type}
                    fullWidth
                    value={inputValues[field.name] || ""}
                    onChange={(e) =>
                      onInputChange(section, field.name, e.target.value)
                    }
                    InputProps={
                      field.name === "BrutoMaandsalaris"
                        ? {
                          startAdornment: (
                            <InputAdornment position="start">
                              <EuroIcon />
                            </InputAdornment>
                          ),
                        }
                        : undefined
                    }
                    select={field.type === "select"}
                  >
                    {field.type === "select" &&
                      field.options?.map((option, optionIndex) => (
                        <MenuItem
                          key={optionIndex}
                          value={option.value.toString()}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </React.Fragment>
  );


  export default PriceCard;