import React from "react";
import { Typography, Card, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BarChart } from "@mui/x-charts";
import { InfoInput } from "../../models/InfoInput";
import { TalentFactory } from "../../methodes/TalentFactory";
import { TalentCost } from "../../methodes/TalentCost";
import { LaborMarketDataModel } from "../../models/LaborMarketDataModel";
import { SectorCorrectiefactor } from "../../models/SectorCorrectiefactor";
import { ReferenceDataService } from "../../services/ReferencesService";

interface ChartsProps {
  infoInput: InfoInput
}

const Charts: React.FC<ChartsProps> = ({ infoInput }) => {
  const { t } = useTranslation();

  const [laborMarketData, setLaborMarketData] = React.useState<LaborMarketDataModel[]>([]);
  const [sectorCorrectiefactor, setSectorCorrectiefactor] = React.useState<SectorCorrectiefactor[]>([]);

  React.useEffect(() => {
    ReferenceDataService.SectorCorrectiefactor().then((data) => {
      setLaborMarketData(data.laborMarketData ?? []);
      setSectorCorrectiefactor(data.sectors ?? []);
    });
  });

  const categories = ['Vast', 'Uitzenden', 'ZZP Direct', 'ZZP Indirect', 'Detacheren'];

  const talentCostVast = TalentFactory.getVastTalent();
  talentCostVast.infoInput = infoInput;
  talentCostVast.laborMarketData = laborMarketData;
  talentCostVast.sectorCorrectiefactor = sectorCorrectiefactor;


  const talentCostUitzenden = TalentFactory.getUitzendenTalent();
  talentCostUitzenden.infoInput = infoInput;
  talentCostUitzenden.laborMarketData = laborMarketData;
  talentCostUitzenden.sectorCorrectiefactor = sectorCorrectiefactor;

  const talentCostZZPDirect = TalentFactory.getZzpdirectTalent()
  talentCostZZPDirect.infoInput = infoInput;
  talentCostZZPDirect.laborMarketData = laborMarketData;
  talentCostZZPDirect.sectorCorrectiefactor = sectorCorrectiefactor;

  const talentCostZZPIndirect = TalentFactory.getZzpInDirectTalent()
  talentCostZZPIndirect.infoInput = infoInput;
  talentCostZZPIndirect.laborMarketData = laborMarketData;
  talentCostZZPIndirect.sectorCorrectiefactor = sectorCorrectiefactor;

  const talentCostDetacheren = TalentFactory.getDetacherenTalent()
  talentCostDetacheren.infoInput = infoInput;
  talentCostDetacheren.laborMarketData = laborMarketData;
  talentCostDetacheren.sectorCorrectiefactor = sectorCorrectiefactor;


  let calculationstosend = [
    { label: "Kosten per jaar", valueVast: TalentCost.getNumber(talentCostVast.calculateJaarlijkseKosten()), valueUitzenden: TalentCost.getNumber(talentCostUitzenden.calculateJaarlijkseKosten()), valueZZPDirect: TalentCost.getNumber(talentCostZZPDirect.calculateJaarlijkseKosten()), valueZZPIndirect: TalentCost.getNumber(talentCostZZPIndirect.calculateJaarlijkseKosten()), valueDetacheren: TalentCost.getNumber(talentCostDetacheren.calculateJaarlijkseKosten()) },
    { label: "Tarief per uur", valueVast: TalentCost.getNumber(talentCostVast.calculateProductiefUurtarief()), valueUitzenden: TalentCost.getNumber(talentCostUitzenden.calculateProductiefUurtarief()), valueZZPDirect: TalentCost.getNumber(talentCostZZPDirect.calculateProductiefUurtarief()), valueZZPIndirect: TalentCost.getNumber(talentCostZZPIndirect.calculateProductiefUurtarief()), valueDetacheren: TalentCost.getNumber(talentCostDetacheren.calculateProductiefUurtarief()) },
  ]

  const formatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const valueFormatter = (value: number | null) => value != null && value > 0 ? formatter.format(value) : "n.v.t";// `${value}mm`;

  return (
    <div>
      <Grid container spacing={2}>
        {calculationstosend.map((chart, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <Card sx={{ my: 2 }} >
              <Typography variant="h5" component="div" sx={{ p: 2, width: "100%", textAlign: "center", backgroundColor: "#f2f2f2" }}>
                {t(chart.label)}
              </Typography>
              <BarChart

                yAxis={[
                  {
                    scaleType: 'band',
                    data: categories,
                    disableTicks: true,
                    tickLabelStyle: { fontSize: '10px', fontWeight: "bold", textAnchor: 'end' },
                    colorMap: {
                      type: 'ordinal',
                      colors: ['#009ba7', '#006466', '#044e7f', '#1477B7', '#179D49']
                    }
                  }
                ]}
                layout="horizontal"
                series={[{ valueFormatter: valueFormatter, data: [chart.valueVast, chart.valueUitzenden, chart.valueZZPDirect, chart.valueZZPIndirect, chart.valueDetacheren] }]}
                height={350}
                sx={{ p: 4 }}
              />
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Charts;
